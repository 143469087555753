





































































import Vue from "vue";
import DetailsCard from "@/components/loans/details/DetailsCard.vue";
import { mapGetters } from "vuex";
import { Loan } from "@/types";

export default Vue.extend({
  name: "CurrentCard",
  components: { DetailsCard },
  props: { loanId: { type: Number, required: true } },
  methods: {
    goToLoanPayments() {
      this.$router.push("/payments/" + this.loanId);
    },
  },
  computed: {
    ...mapGetters({
      loans: "loans/getLoans",
      accounts: "account/getAccount",
    }),
    loan(): any {
      return this.loans.filter((l: Loan) => l.ac_deal_id === this.loanId)[0];
    },
  },
});
