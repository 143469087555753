













































import Vue from "vue";
import { apiAxios } from "@/store/apiAxios";

export default Vue.extend({
  name: "Mt940Form",
  data: () => ({
    file: null as unknown as File[],
    fileType: "",
    formData: new FormData(),
  }),
  props: ["loanId"],
  methods: {
    handleFileUpload(file: File[]) {
      if (file.length > 0) {
        this.file = file;
      } else {
        this.file = null as unknown as File[];
      }
    },

    storeDocument() {
      let formData = new FormData();
      if (this.file !== null) {
        if (this.file.length > 1) {
          for (let i = 0; i < this.file.length; i++) {
            formData.append("files[" + i + "]", this.file[i]);
            formData.append("type", "mt940");
          }
        } else {
          formData.append("file", this.file[0]);
          formData.append("type", "mt940");
        }
        formData.append("loanId", this.loanId);

        try {
          apiAxios.post("/api/loans/documents/", formData).then((r) => {
            if (r.data.message === "Success") {
              this.$bvModal.show("mt940-message-success");
            } else {
              this.$bvModal.show("mt940-message-failed");
            }
          });
        } catch (error) {
          this.$bvModal.show("mt940-message-failed");
        }
      } else {
        this.$bvModal.show("mt940-file-missing");
      }
    },
  },
});
