

































































































































































































import Vue from "vue";
import { Loan } from "@/types";
import { Document } from "@/types";
import { mapGetters } from "vuex";
import PageHeading from "@/components/PageHeading.vue";
import CurrentCard from "@/components/loans/details/CurrentCard.vue";
import ExpirationCard from "@/components/loans/details/ExpirationCard.vue";
import ExpirationList from "@/components/loans/ExpirationList.vue";
import Mt940Form from "@/components/loans/details/Mt940Form.vue";
import ModalContainer from "@/components/ModalContainer.vue";

export default Vue.extend({
  name: "LoanDetails",
  components: {
    PageHeading,
    CurrentCard,
    Mt940Form,
    ModalContainer,
    // ExpirationCard,
    ExpirationList,
    // RepaidCard,
    // BIconCloudArrowDownFill,
  },
  data: () => ({
    possibleProps: [
      "loan_start_date",
      "loan_end_date",
      "totalamount",
      "outstanding",
      "arrears",
    ] as unknown as (keyof Loan)[],
    featureVisibile: null as unknown as boolean,
  }),
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  created() {
    if (this.loan.ac_account_id) {
      this.$store.dispatch("account/fetchAccount", {
        id: this.loan.ac_account_id,
      });
    } else {
      this.$store.dispatch("account/fetchAccount", { id: 110870 });
    }

    this.featureVisibile = process.env.VUE_APP_SHOW_FEATURE;
  },
  computed: {
    ...mapGetters({
      loans: "loans/getLoans",
      accounts: "account/getAccount",
      userAuth: "auth/authUser",
    }),
    loan(): any {
      return this.loans.filter((l: Loan) => l.ac_deal_id === this.id)[0];
    },
    documents(): Document[] {
      if (this.loans === undefined) return [];

      return this.loans.documents;
    },
    showFeature(): boolean {
      if (process.env.VUE_APP_SHOW_FEATURE !== "true") {
        return false;
      }

      return true;
    },
    emptyBankstatement: {
      get(): boolean {
        return this.$store.getters['bankstatement/getEmptyBankstatement'];
      },

      set(value: boolean) {
        this.$store.commit('bankstatement/setEmptyBankstatement', value);
      }
    }
  },
  methods: {
    getFilename(url: string): string {
      return url.substring(url.lastIndexOf("/") + 1);
    },
    downloadFile(document: any) {
      this.$store.dispatch("loans/downloadLoanDocument", document);
    },
  },
});
